import React from "react"
import styled from "styled-components"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Grid from "../components/grid"

import ImageWithText from "../components/imageWithText"




export const query = graphql`
query Conductor {
    mdx(fileAbsolutePath: {regex: "/conductor.md/"}) {
        frontmatter {
            heading
            hero {
                childImageSharp {
                    fluid(maxWidth: 2500) {
                        src
                    }
                }
            }
            profile {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            current
            past
        }
        body
    }
    allMdx(filter: {fileAbsolutePath: {regex: "/choirs/"}}) {
        nodes {
          frontmatter {
            name
            picture {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                    src
                }
              }
            }
            website
            present
          }
        }
    }
}
`

const ConductorPage = ({ data }) => {
    const conductorImage = data.mdx.frontmatter.hero.childImageSharp.fluid.src
    const profilePic = data.mdx.frontmatter.profile.childImageSharp.fluid
    const choirs = data.allMdx.nodes
    const current = choirs.filter(item => item.frontmatter.present)
    const past = choirs.filter(item => !item.frontmatter.present)
    const content = data.mdx

    return (
        <>
            <SEO
                title="Conductor"
                keywords={[`conductor`, `choral conductor`, `choir conductor`, `choir`, `chorus`]} />

            <Hero imageSrc={conductorImage}>
                <h1>{content.frontmatter.heading}</h1>
            </Hero>

            <PageLayout>
                <ProfilePic fluid={profilePic} />
                <ProfileText>
                    <MDXRenderer>{content.body}</MDXRenderer>
                </ProfileText>
            </PageLayout>
            <div
                style={{
                    marginTop: "2rem",
                }}>
                {current &&
                    <div>
                        <Subheading>{content.frontmatter.current}</Subheading>
                        <Grid columns="2" gap="4px">
                            {current.map((choir) => {

                                return (
                                    <a href={choir.frontmatter.website} className="grid-item" key={choir.frontmatter.name}>
                                        <ImageWithText
                                        isSquare={true}
                                            src={choir.frontmatter.picture.childImageSharp.fluid}
                                            background={props => props.theme.colors.primary}
                                        >
                                            {choir.frontmatter.name}
                                        </ImageWithText>
                                    </a>
                                )

                            })}
                        </Grid>
                    </div>
                }
                {past &&
                    <div>
                        <Subheading>{content.frontmatter.past}</Subheading>
                        <Grid columns="2" gap="4px">
                            {past.map((choir) => {


                                return (

                                    <a href={choir.frontmatter.website} key={choir.frontmatter.name} target="_blank" rel="noreferrer noopener">
                                            <ImageWithText
                                            isSquare={true}
                                            src={choir.frontmatter.picture.childImageSharp.fluid}
                                            background={props => props.theme.colors.primary}

                                        >
                                            {choir.frontmatter.name}
                                            </ImageWithText>
                                    </a>

                                )

                            })}
                        </Grid>
                    </div>
                }
            </div>

        </>
    )
}



export default ConductorPage

const ProfilePic = styled(BackgroundImage)`
height: 250px;
margin: 1rem 0;
flex: 0 3 50%;
@media (min-width: 786px) {
    height: auto;
    margin: 0.5rem 1rem 0 0;
    justify-content: flex-start;
    background-size: auto 100%;
}
`
const ProfileText = styled.div`
flex: 2 0 50%;

`
const PageLayout = styled.div`
width: 100%;
display: flex;
flex-direction: column;

@media (min-width: 786px) {
    flex-direction: row;
}
`
const Subheading = styled.h2`
text-align: center;
`
